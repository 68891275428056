import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import ReportHeader from 'components/ReportHeader'

const Header = ({ handlePrint, isPrinting }) => {
  return (
    <FormattedMessage id='reports.stoppageTrend.name'>
      {text => (
        <ReportHeader name={text} handlePrint={handlePrint}>
          <div
            className='page-header report-header'
            style={{ width: 100, border: 0, margin: 0, display: isPrinting ? 'none' : 'block' }}
          />
        </ReportHeader>
      )}
    </FormattedMessage>
  )
}

Header.propTypes = {
  handlePrint: PropTypes.func.isRequired,
  isPrinting: PropTypes.bool
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
