import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ToggleButton from 'components/ToggleButton'
import { selectLocation, selectVisibleLiocationId } from './selectors'
import { showLocation } from './actions'

const StoppageTrendReportLocationToggle = ({ id, symbol, visibleId, show }) => (
  <ToggleButton
    isToggled={visibleId === id}
    onToggleOn={() => show(id)}
    onToggleOff={() => {}}
    color='default'
  >
    {symbol}
  </ToggleButton>
)

StoppageTrendReportLocationToggle.propTypes = {
  id: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
  visibleId: PropTypes.number,
  show: PropTypes.func.isRequired
}

const mapStateToProps = (state, { id }) => ({
  ...selectLocation(state, id),
  visibleId: selectVisibleLiocationId(state)
})

const mapDispatchToProps = ({
  show: showLocation
})

export default connect(mapStateToProps, mapDispatchToProps)(StoppageTrendReportLocationToggle)
