import { connect } from 'react-redux'
import { selectLocationIds } from './selectors'
import StoppageTrendReportLocationToggle from './StoppageTrendReportLocationToggle'
import ProductionReportLocations from 'components/ProductionReportLocations'

const mapStateToProps = (state) => ({
  ids: selectLocationIds(state),
  Toggle: StoppageTrendReportLocationToggle
})

export default connect(mapStateToProps)(ProductionReportLocations)
