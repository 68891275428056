import { createSelector } from 'reselect'

export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone

export const selectLocationIds = state => state.locations.allIds
export const selectLocation = (state, id) => state.locations.allById[id]
export const selectVisibleLiocationId = state => state.locations.visible

export const selectReports = state => state.reports.reports
export const selectReportDataKeys = createSelector(
  selectReports,
  (reports) => {
    const lastReport = reports[reports.length - 1]
    if (!lastReport) return []

    const { cw, descriptions, ...data } = lastReport

    return Object.entries(data).sort((a, b) => b[1] - a[1]).map(([key]) => key)
  }
)
