import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  SHOW_LOCATION: 'SHOW_LOCATION',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  RECEIVE_REPORTS: 'RECEIVE_REPORTS'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const showLocation = createAction(Actions.SHOW_LOCATION)
export const receiveReports = createAction(Actions.RECEIVE_REPORTS)
