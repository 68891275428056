import api from 'lib/api/client'
import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment-timezone'
import heartbeat from 'lib/saga/heartbeat'
import Actions, {
  startInit,
  finishInit,
  receiveReports,
  receiveLocations
} from './actions'
import {
  selectToken,
  selectTimezone,
  selectVisibleLiocationId
} from './selectors'
import { SET_NAVIGATION,
  setNavigationDate,
  configureNavigation
} from 'containers/Navigation/actions'
import { TYPE_CWEEK } from 'containers/Navigation/const'
import { selectNavigationRange } from 'containers/Navigation/selectors'

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Reports:StoppageTrend')
  }

  yield call(init)
  yield call(reports)
  yield takeLatest(
    [
      Actions.SHOW_LOCATION,
      Actions.RECEIVE_LOCATIONS,
      SET_NAVIGATION
    ],
    reports
  )
}

function * init () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  yield put(startInit())

  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone).subtract(1, 'week')))
  yield put(configureNavigation(TYPE_CWEEK))

  const [locations] = yield all([
    call(api.get, '/locations', { token })
  ])

  yield put(receiveLocations(locations))

  yield put(finishInit())
}

function * reports () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  const timeParams = yield select(selectNavigationRange, timezone)
  const locationId = yield select(selectVisibleLiocationId)
  if (!locationId) return

  const params = {
    ...timeParams,
    locationId
  }

  const [reports] = yield all([
    call(api.get, '/reports/stoppage_trend', { params, token })
  ])

  yield put(receiveReports(reports))
}
