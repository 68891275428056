import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { allById, allIds } from 'lib/reducers'
import Actions from 'reports/stoppageTrend/actions'
import navigation from 'containers/Navigation/reducer'

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const locations = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => {
      const allLocationIds = allIds(locations)

      return {
        allById: allById(locations),
        allIds: allLocationIds,
        visible: allLocationIds[0]
      }
    },
    [Actions.SHOW_LOCATION]: (state, { payload: id }) => ({
      ...state,
      visible: id
    })
  },
  { allIds: [], visible: null, allById: {} }
)

const reports = handleActions(
  {
    [Actions.RECEIVE_REPORTS]: (state, { payload }) => ({
      ...state,
      reports: payload
    })
  },
  { reports: [] }
)

export default combineReducers({
  init,
  config,
  locations,
  reports,
  navigation
})
