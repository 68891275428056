import React from 'react'
import { PrintContextConsumer } from 'react-to-print'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import ReportContainer from 'components/ReportContainer'
import StoppageTrendReportHeader from './StoppageTrendReportHeader'
import StoppageTrendReportGraph from './StoppageTrendReportGraph'
import StoppageTrendReportLocations from './StoppageTrendReportLocations'

const StoppageTrendReport = ({ isLoaded }) => {
  if (!isLoaded) {
    return <Loader full />
  }

  return (
    <ReportContainer selector='#stoppage-trend-report.report'>
      {({ isPrinting }) => (
        <React.Fragment>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <StoppageTrendReportHeader isPrinting={isPrinting} handlePrint={handlePrint} />
            )}
          </PrintContextConsumer>
          <StoppageTrendReportLocations />
          <StoppageTrendReportGraph isPrinting={isPrinting} />
        </React.Fragment>
      )}
    </ReportContainer>
  )

}

StoppageTrendReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(StoppageTrendReport)
