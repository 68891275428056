import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { selectReports, selectReportDataKeys } from './selectors'
import { connect } from 'react-redux'
import {
  ResponsiveContainer,
  ComposedChart,
  Legend,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  YAxis
} from 'recharts'
import Unit from '../../components/Unit'

const COLORS = [
  'blue',
  'red',
  'Khaki',
  'green',
  'orange',
  'turquoise',
  'purple'
]

const StoppageTrendTooltip = ({ payload }) => {
  if (!payload || !payload[0]) {
    return null
  }

  const { cw } = payload[0].payload

  return (
    <div className='recharts-custom-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>
          <FormattedMessage id='calendarWeek' values={{ number: cw }} />
        </h4>
      </div>
      <div className='report-graph-tooltip__footer'>
        {payload.map((entry) => (
          <div key={entry.dataKey} style={{ display: 'flex' }}>
            <div className='recharts-custom-tooltip__label' style={{ color: entry.fill }}>
              {`${entry.name.toUpperCase()} - ${entry.payload.descriptions[entry.dataKey]}`}
              &nbsp;
            </div>
            <div className='recharts-custom-tooltip__value'>
              <Unit value={entry.value} unit='minute' />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const StoppageTrendReportGraph = ({ data, dataKeys, isPrinting }) => {
  return (
    <div
      className='report-recharts-container'
      style={{ width: '100%', height: 500, marginTop: 18, marginBottom: 18 }}
    >
      <FormattedMessage id='calendarWeek'>
        {(cweekTranslation) => (
          <ResponsiveContainer>
            <ComposedChart
              width={600}
              height={400}
              data={data}
              margin={{ top: 20, right: 20, bottom: 120, left: 20 }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <Legend formatter={(k) => `${k.toUpperCase()} - ${data[data.length - 1].descriptions[k]}`} />
              <Tooltip content={<StoppageTrendTooltip />} />

              {dataKeys.map((key, idx) => (
                <Bar key={key} dataKey={key} fill={COLORS[idx]} barSize={20} />
              ))}

              <XAxis
                dataKey='cw'
                tickFormatter={cw => cweekTranslation.replace('{number}', cw)}
              />
              <YAxis
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </FormattedMessage>
    </div>
  )
}

const mapStateToProps = state => ({
  data: selectReports(state),
  dataKeys: selectReportDataKeys(state)
})

StoppageTrendReportGraph.propTypes = {
  data: PropTypes.array,
  dataKeys: PropTypes.array,
  isPrinting: PropTypes.bool
}

export default connect(mapStateToProps)(StoppageTrendReportGraph)
